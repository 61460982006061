<template>
  <modal
    :name="name"
    :width="450"
    :adaptive="true"
    :click-to-close="clickToClose"
    height="auto"
    @closed="handleClosed"
  >
    <div class="p-6 text-body font-normal">
      <slot />
    </div>
  </modal>
</template>

<script>
  export default {
    props: {
      // v-model
      value: {
        type: Boolean,
        default: false,
      },

      clickToClose: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        name: Math.random().toString(),
      }
    },

    watch: {
      value(val) {
        if (val === true) {
          this.$modal.show(this.name)
        } else {
          this.$emit('close')
        }
      },
    },

    methods: {
      handleClosed() {
        // v-model
        this.$emit('input', false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ .v--modal {
    @apply border border-border rounded-lg;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  .v--modal-overlay {
    background: rgba(255, 255, 255, 0.65) !important;
  }
</style>
