<template>
  <div :class="{ 'flex flex-col md:flex-row items-center': !isLeadCaptureActivated }">
    <LeadCapture :are-signups-open="areSignupsOpen" variant="lander" @activate="isLeadCaptureActivated = true" />
    <div v-if="!isLeadCaptureActivated" class="flex-grow text-sm text-slight md:ml-4 mt-2 md:mt-0 leading-relaxed text-center md:text-left">
      14-day free trial<br class="hidden md:block"><span class="md:hidden"> &middot; </span><span>No credit card required</span>
    </div>
  </div>
</template>

<script>
  import LeadCapture from '@application/components/lead-capture.vue'

  export default {
    components: {
      LeadCapture,
    },

    props: {
      areSignupsOpen: Boolean,
    },

    data() {
      return {
        isLeadCaptureActivated: false,

        // If organization is referring current lead, this object will exist
        referrerOrganization: window.referrerOrganization,
      }
    },

    computed: {
      isReferred() {
        return this.referrerOrganization != undefined
      },
    }
  }
</script>
