<template>
  <span @click="openIntercom">
    <slot />
  </span>
</template>

<script>
  export default {
    props: {
      location: {
        type: String,
        default: null,
      },
    },

    methods: {
      openIntercom(e) {
        e.preventDefault()

        window.Intercom('showNewMessage')

        window.analytics.track('Intercom Triggered', {
          location: this.location,
        })
      },
    },
  }
</script>
