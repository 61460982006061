<template>
  <div :class="['btn relative', classes, { 'btn--disabled': isLoading }]">
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center">
      <Spinner />
    </div>
    <div :class="{ invisible: isLoading }">
      <slot />
    </div>
  </div>
</template>

<script>
  import Spinner from '@application/components/spinner.vue'

  export default {
    components: {
      Spinner,
    },

    props: {
      classes: {
        type: [Array, String],
      },

      isLoading: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

