import Vue from 'vue'
import VModal from 'vue-js-modal'
import { upperFirst, camelCase } from 'lodash'

Vue.use(VModal)

document.addEventListener('DOMContentLoaded', () => {
  // Setup function used to resolve components
  const requireComponent = require.context(
    // The relative path of the components folder
    '@application/components',

    // Whether or not to look in subfolders
    true,

    // The regular expression used to match base component filenames
    /.vue$/,
  )

  // Mount components
  Array
    .from(document.getElementsByClassName('vue-component'))
    .forEach((el) => {

    const componentFileName = el.dataset.component
    const componentProperties = JSON.parse(el.dataset.properties)

    // We generate component name that is in this format: ComponentName
    const componentName = upperFirst(camelCase(componentFileName.replace('/', '-')))

    // Pass in components that utilizes earlier function to resolve
    const componentsConfig = {
      [componentName]: requireComponent(`./${componentFileName}.vue`).default,
    }

    new Vue({
      el,

      components: componentsConfig,

      data() {
        return { properties: componentProperties }
      },

      template: `<${componentName} v-bind="properties">${el.innerHTML}</${componentName}>`,
    })
  })
})
